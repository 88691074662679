/* @flow */

import React from "react";
import { Link } from "react-router-dom";
import { Button as ButtonComponent } from "@crossroads/ui-components";

import styles from "./styles.scss";
import cn from "classnames";

type Props = {
  children: React$Node,
  className?: string,
  to?: string,
  external?: boolean,
};

const InnerButton = ({
  children,
  className,
  ...props
}: {
  children: React$Node,
  className?: string,
}) => {
  return (
    <ButtonComponent
      {...props}
      className={cn(styles.button, className)}
    >
      <span className={styles.content}>
        {children}
      </span>
      <span className={styles.content__left} />
      <span className={styles.content__right} />
    </ButtonComponent>
  );
};

const Button = ({ to = "", className, children, external = false, ...props }: Props): React$Node => {
  if (to.length > 0) {
    return (
      <Link to={{ pathname: to }} className={className} target={external ? "_blank" : "_self"} style={{ textDecoration: "none" }}>
        <InnerButton {...props}>{children}</InnerButton>
      </Link>
    );
  }

  return (
    <InnerButton {...props} className={className}>
      {children}
    </InnerButton>
  );
};

export default Button;
