/* @flow */

import type { SortableProductList } from "shop-state/types";

import React from "react";
import FilterableProductList, { FilterableProductListHint } from "components/FilterableProductList";
import { Helmet } from "react-helmet-async";
import { useData, useSendMessage } from "crustate/react";
import { BrandData } from "data";
import ProductListHero, { ProductListHeroHint } from "components/ProductListHero";
import { load as loadBrand } from "@crossroads/shop-state/brand";
import { useLocation } from "react-router-dom";
import useBreadcrumbLinks from "helpers/use-breadcrumb-links";

import styles from "../CategoryView/styles.scss";

type BrandProps = {
  brand: { brand: string, data: SortableProductList, state: "LOADED" }
  | { brand: string, data: SortableProductList, state: "UPDATING" },
};

type Props = {
  hint?: { category: { name: string } },
};

const BrandViewWrapper = ({ hint }: Props): React$Node => {
  const brand = useData(BrandData);

  if (hint && brand.state !== "LOADED" && brand.state !== "UPDATING") {
    return <HintBrandView hint={hint} />;
  }

  if (brand.state === "LOADED" || brand.state === "UPDATING") {
    return <BrandView brand={brand} />;
  }

  return null;
};

const BrandView = ({ brand }: BrandProps): React$Node => {
  const sendMessage = useSendMessage();
  const updating = brand.state === "UPDATING";
  const load = location => sendMessage(loadBrand(brand.brand, location, true));
  const location = useLocation();
  const breadcrumbLinks = useBreadcrumbLinks({ current: brand.brand });

  return (
    <div className={styles.block}>
      <Helmet title={brand.brand} />

      <ProductListHero
        title={brand.brand}
        totalCount={brand.data.totalCount}
        breadcrumbLinks={breadcrumbLinks}
        breadcrumbCurrent={brand.brand}
      />

      <FilterableProductList
        updating={updating}
        productList={brand.data}
        load={load}
        breadcrumbLinks={[{
          pathname: `/brand/${encodeURIComponent(brand.brand)}`,
          search: location.search,
          title: brand.brand,
          hint: {
            name: brand.brand,
          },
        }]}
        category={brand.brand}
      />
    </div>
  );
};

const HintBrandView = ({ hint }: { hint: { category: { name: string } } }): React$Node => {
  const breadcrumbLinks = useBreadcrumbLinks({ current: hint.category.name });

  return (
    <div className={styles.block}>
      <Helmet title={hint.category.name} />
      <ProductListHeroHint
        title={hint.category.name}
        breadcrumbLinks={breadcrumbLinks}
        breadcrumbCurrent={hint.category.name}
      />
      <FilterableProductListHint />
    </div>
  );
};

export default BrandViewWrapper;

