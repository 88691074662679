/* @flow */

import type { BlockSplit, BlockCategory } from "state/home";

import React from "react";
import cn from "classnames";
import Button from "components/Button";

import styles from "./styles.scss";

type BlockProps = {
  buttonModifier?: string,
  className?: string,
  block: BlockCategory,
};

type HeroProps = {
  buttonModifier?: string,
  className?: string,
  block: BlockCategory,
};

type BlockSplitHeroProps = {
  buttonModifier?: string,
  className?: string,
  block: BlockSplit,
};

const Hero = ({
  className,
  block,
  buttonModifier = "primary",
}: HeroProps): React$Node => {
  const images = [
    {
      media: "(max-width: 400px)",
      srcSet: block.smallImage ? `${block.smallImage.x1}, ${block.smallImage.x2} 2x` : "",
    },
    {
      media: "(min-width: 401px and max-width: 800px)",
      srcSet: block.mediumImage ? `${block.mediumImage.x1}, ${block.mediumImage.x2} 2x` : "",
    },
    {
      media: "(min-width: 801px)",
      srcSet: block.largeImage ? `
          ${block.largeImage.w1280} 1280w,
          ${block.largeImage.w1440} 1440w,
          ${block.largeImage.w1600} 1600w,
          ${block.largeImage.w1920} 1920w,
          ${block.largeImage.w2560} 2560w,
          ${block.largeImage.w3840} 3840w,
          ${block.largeImage.w4320} 4320w,
        ` : "",
    },
  ].filter(blurImage => blurImage.srcSet);

  return (
    <div
      className={cn(
        styles.block,
        className
      )}
      style={{ backgroundColor: block.modifier }}
    >
      <picture className={styles.image} alt={block.heading}>
        {images.map(({ srcSet, media }) => (
          <source
            key={srcSet}
            srcSet={srcSet}
            media={media}
          />
        ))}

        <img
          alt={block.heading}
          draggable="false"
          src={block.largeImage.w1920}
          data-object-fit="cover"
        />
      </picture>

      <div className={styles.contentWrapper}>
        {block.heading &&
          <h1>{block.heading}</h1>
        }
        {block.text &&
          <h2>{block.text}</h2>
        }
        {block.link && block.cta &&
          <Button
            external
            to={block.link}
            variant={buttonModifier}
            className={styles.cta}
          >
            {block.cta}
          </Button>
        }
      </div>
    </div>
  );
};

export const BlockSplitHero = ({
  className,
  block,
  buttonModifier = "primary",
}: BlockSplitHeroProps): React$Node => {
  const images = [
    {
      media: "(max-width: 400px)",
      srcSet: `${block.imageSmall ? `${block.imageSmall.x1}, ${block.imageSmall.x2} 2x` : ""}`,
    },
    {
      media: "(min-width: 801px)",
      srcSet: `${block.image ? `${block.image.x1}, ${block.image.x2} 2x` : ""}`,
    },
  ].filter(blurImage => blurImage.srcSet);

  return (
    <div
      className={cn(
        styles.block,
        styles.blockSplitHero,
        className
      )}
      style={{ backgroundColor: block.modifier }}
    >
      <picture className={styles.image} alt={block.heading}>
        {images.map(({ srcSet, media }) => (
          <source
            key={srcSet}
            srcSet={srcSet}
            media={media}
          />
        ))}

        <img
          alt={block.heading}
          draggable="false"
          src={block.image.x1}
          data-object-fit="cover"
        />
      </picture>
      <div className={styles.contentWrapper}>
        {block.heading &&
          <h2>{block.heading}</h2>
        }
        {block.text &&
          <h2>{block.text}</h2>
        }
        {block.link && block.cta &&
          <Button
            to={block.link}
            className={styles.cta}
            variant={buttonModifier}
          >
            {block.cta}
          </Button>
        }
      </div>
    </div>
  );
};

export const BlockHero = ({
  className,
  block,
  buttonModifier = "primary",
}: BlockProps): React$Node => {
  const images = [
    {
      media: "(max-width: 400px)",
      srcSet: block.smallImage ? `${block.smallImage.x1}, ${block.smallImage.x2} 2x` : "",
    },
    {
      media: "(min-width: 401px and max-width: 800px)",
      srcSet: block.mediumImage ? `${block.mediumImage.x1}, ${block.mediumImage.x2} 2x` : "",
    },
    {
      media: "(min-width: 801px)",
      srcSet: block.largeImage ? `
          ${block.largeImage.w1280} 1280w,
          ${block.largeImage.w1440} 1440w,
          ${block.largeImage.w1600} 1600w,
          ${block.largeImage.w1920} 1920w,
          ${block.largeImage.w2560} 2560w,
          ${block.largeImage.w3840} 3840w,
          ${block.largeImage.w4320} 4320w,
        ` : "",
    },
  ].filter(blurImage => blurImage.srcSet);

  return (
    <div
      className={cn(
        styles.block,
        className
      )}
      style={{ backgroundColor: block.modifier }}
    >
      <picture className={styles.image} alt={block.heading}>
        {images.map(({ srcSet, media }) => (
          <source
            key={srcSet}
            srcSet={srcSet}
            media={media}
          />
        ))}

        <img
          alt={block.heading}
          draggable="false"
          src={block.largeImage.w1920}
          data-object-fit="cover"
        />
      </picture>

      <div className={styles.contentWrapper}>
        {block.heading &&
          <h2>{block.heading}</h2>
        }
        {block.text &&
          <h2>{block.text}</h2>
        }

        {block.link && block.cta &&
          <Button
            to={block.link}
            className={styles.cta}
            variant={buttonModifier}
          >
            {block.cta}
          </Button>
        }
      </div>
    </div>
  );
};

export default Hero;
