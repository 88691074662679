/* @flow */

import type {
  SortableProductList,
  FilterableProductList as TFilterableProductList,
} from "shop-state/types";
import type { BreadcrumbLink } from "@crossroads/ui-components";

import React from "react";
import Wrapper from "components/Wrapper";
import ProductList from "components/ProductList";
import PaginatedProductList from "components/PaginatedProductList";
import { PAGE_SIZE } from "effects/route";

type Props = {
  children?: React$Node,
  updating: boolean,
  productList: SortableProductList | TFilterableProductList,
  breadcrumbLinks?: $ReadOnlyArray<BreadcrumbLink>,
  category?: string,
};

const FilterableProductList = ({
  children,
  updating,
  productList,
  breadcrumbLinks,
  category,
}: Props): React$Node => {
  const numPages = Math.ceil(productList.totalCount / PAGE_SIZE);

  return (
    <Wrapper>
      {children}
      <PaginatedProductList
        updating={updating}
        numPages={numPages}
        productList={productList}
        breadcrumbLinks={breadcrumbLinks}
        category={category}
      />
    </Wrapper>
  );
};

export const FilterableProductListHint = ({ children }: { children?: React$Node }): React$Node => (
  <Wrapper>
    {children}
    <ProductList products={[null, null, null, null, null, null, null, null]} />
  </Wrapper>
);

export default FilterableProductList;
