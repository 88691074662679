/* @flow */

import React from "react";
import cn from "classnames";
import Wrapper from "components/Wrapper";
import styles from "./styles.scss";

type Props = {
  title: string,
  description?: string,
  images?: {
    small?: {
      x1: string,
      x2: string,
    },
    medium?: {
      x1: string,
      x2: string,
    },
    large?: {
      x1: string,
      x2: string,
    },
  },
  className?: string,
  short?: boolean,
};

type HintProps = {
  title?: string,
  className?: string,
};

type PrepareImageSourcesProps = {
  small?: {
    x1: string,
    x2: string,
  },
  medium?: {
    x1: string,
    x2: string,
  },
  large?: {
    x1: string,
    x2: string,
  },
};

const prepareImageSources = ({ small, medium, large }: PrepareImageSourcesProps) => {
  const images = [
    {
      media: (medium || large) ? "(max-width: 550px)" : "",
      srcSet: small ? `${small.x1}, ${small.x2} 2x` : "",
    },
    {
      media: large ? "(max-width: 1057px)" : "",
      srcSet: medium ? `${medium.x1}, ${medium.x2} 2x` : "",
    },
    {
      media: "",
      srcSet: large ? `${large.x1}, ${large.x2} 2x` : "",
    },
  ].filter(img => img.srcSet);

  return images;
};

const ProductListHero = ({
  title,
  description,
  images,
  className,
  short = false,
}: Props): React$Node => {
  const { small: smallImage, medium: mediumImage, large: largeImage } = images || {};
  const gotImage = smallImage || mediumImage || largeImage || false;
  const imageSources = images ? prepareImageSources(images) : [];

  return (
    <>
      <div
        className={cn(
          styles.block,
          className,
          { [styles.gotImage]: gotImage },
          { [styles.short]: short }
        )}
      >

        <div className={styles.innerBlock}>
          <div className={styles.imageContainer}>
            {gotImage &&
              <picture
                key={`${title}_heroimage`}
                className={styles.image}
                alt={`Logo ${title}`}
              >
                {imageSources.map(({ srcSet, media }) => (
                  <source
                    key={srcSet}
                    srcSet={srcSet}
                    media={media}
                  />
                ))}
                <img src={smallImage?.x1 || mediumImage?.x1 || largeImage?.x1 || ""} alt={`Logo ${title}`} />
              </picture>
            }
          </div>

          <Wrapper className={styles.wrapper}>
            <h1 className={styles.title}>{title}</h1>
          </Wrapper>
        </div>
      </div>
      <div className={cn(styles.subHero)}>
        <div className={cn(styles.description)}>
          <h2>{title}</h2>
          <p>{description}</p>
        </div>
      </div>
    </>
  );
};

export const ProductListHeroHint = ({
  title,
  className,
}: HintProps): React$Node => {
  return (
    <div className={cn(styles.blockHint, className)}>
      <Wrapper className={styles.wrapper}>
        <div className={styles.content}>
          <div className={styles.text}>
            {title &&
              <h1 className={styles.title}>{title}</h1>
            }
            <p className={styles.description} />
          </div>
        </div>

        <div className={styles.right} />
      </Wrapper>
    </div>
  );
};

export default ProductListHero;
