/* @flow */

type Error = {
  field: string,
  error: string,
};

type Category = {
  +name: string,
  +url: string,
  +products: { +totalCount: number },
};

type TopCategory = {
  +name: string,
  +url: string,
  +children: $ReadOnlyArray<Category>,
  +products: { +totalCount: number },
};

declare type DOMEvent<T> = {
  target: T,
} & Event;

export const focusInvalidField = (e: SyntheticEvent<HTMLFormElement>, errors: Array<Error>) => {
  const field = e.currentTarget.querySelector(`[name='${errors[0].field}']`);

  if (field) {
    field.focus();
  }
};

export const loadScript = (isLoaded: () => boolean, src: string, cb: () => void): void => {
  if (!process.browser) {
    return;
  }

  if (isLoaded()) {
    cb();

    return;
  }

  const head = document.querySelector("head");
  const script = document.createElement("script");

  script.src = src;

  /* eslint-disable unicorn/prefer-add-event-listener */
  script.onload = () => {
    script.onload = null;

    cb();
  };
  /* eslint-enable unicorn/prefer-add-event-listener */

  if (head) {
    head.append(script);
  }
};

export const isValidHex = (hex: string): boolean => /^[\da-f]{6}$/i.test(hex);

export const sortCategories = (
  categories: $ReadOnlyArray<TopCategory>,
  locale: string): Array<TopCategory> => {
  return categories.slice().sort((a, b) => a.name.localeCompare(b.name, locale));
};

export const filterCategories = (
  categories: $ReadOnlyArray<TopCategory>): Array<TopCategory> => {
  return categories
    .filter(c => c.products.totalCount > 0)
    .map(c => ({ ...c, children: c.children.filter(c => c.products.totalCount > 0) }));
};

export const removeExampleEmail = <T: { email: ?string }>(object: T): T => {
  if (object && object.email && /@example.com$/.test(object.email)) {
    return {
      ...object,
      email: null,
    };
  }

  return object;
};

// Fisher-Yates shuffle
export const shuffle = (array: $ReadOnlyArray<any>): $ReadOnlyArray<any> => {
  const newArray = array.slice();
  let currentIndex = array.length;
  let temporaryValue;
  let randomIndex;

  // While there remain elements to shuffle...
  while (currentIndex !== 0) {
    // Pick a remaining element...
    randomIndex = Math.floor(Math.random() * currentIndex);
    currentIndex -= 1;

    // And swap it with the current element.
    temporaryValue = array[currentIndex];
    newArray[currentIndex] = array[randomIndex];
    newArray[randomIndex] = temporaryValue;
  }

  return newArray;
};
