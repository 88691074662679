/* @flow */

import { useEffect, useState } from "react";
import { throttle } from "diskho";

type Direction = "lt" | "gt";

const useDevice = (direction: Direction, breakpoint: number = 550): boolean => {
  const [width, setWidth] = useState(process.browser ? window.innerWidth : 1200);

  useEffect(() => {
    if (process.browser) {
      const handleResize = throttle(() => setWidth(window.innerWidth), 1000);
      window.addEventListener("resize", handleResize);
      return () => window.removeEventListener("resize", handleResize);
    }
  }, [width]);

  return direction === "gt" ? width > breakpoint : width < breakpoint;
};

export default useDevice;
