/* @flow */

import type { Category, HintCategory } from "shop-state/types";

import React, { useState } from "react";
import { Helmet } from "react-helmet-async";
import { useTranslate } from "@awardit/react-use-translate";
import { Dropdown, DropdownItem } from "@crossroads/ui-components";
import { getCategoryMeta } from "helpers/get-meta";
import { useLocation, Link } from "react-router-dom";
import ProductListHero, { ProductListHeroHint } from "components/ProductListHero";
import FilterableProductList, { FilterableProductListHint } from "components/FilterableProductList";
import { useData, useSendMessage } from "crustate/react";
import { RouteData } from "data";
import { load as loadRoute } from "@crossroads/shop-state/route";
import useBreadcrumbLinks from "helpers/use-breadcrumb-links";
import Button from "components/Button";

import styles from "./styles.scss";

type CategoryImages = {
  +clsCatfieldsGeneralImgL: ?{ x1: string, x2: string },
  +clsCatfieldsGeneralImgM: ?{ x1: string, x2: string },
  +clsCatfieldsGeneralImgS: ?{ x1: string, x2: string },
};

type CategoryProps = {
  category: Category,
};

type HintCategoryProps = {
  category: HintCategory,
};

const getHeroImages = (category: CategoryImages) => {
  const images = {};

  if (category.clsCatfieldsGeneralImgS) {
    images.small = {
      x1: category.clsCatfieldsGeneralImgS.x1,
      x2: category.clsCatfieldsGeneralImgS.x2,
    };
  }

  if (category.clsCatfieldsGeneralImgM) {
    images.medium = {
      x1: category.clsCatfieldsGeneralImgM.x1,
      x2: category.clsCatfieldsGeneralImgM.x2,
    };
  }

  if (category.clsCatfieldsGeneralImgL) {
    images.large = {
      x1: category.clsCatfieldsGeneralImgL.x1,
      x2: category.clsCatfieldsGeneralImgL.x2,
    };
  }

  return images;
};

const CategoryView = ({ category }: CategoryProps): React$Node => {
  const t = useTranslate();
  const meta = getCategoryMeta(category);
  const route = useData(RouteData);
  const sendMessage = useSendMessage();
  const updating = route.state === "UPDATING";
  const load = location => sendMessage(loadRoute(location, true));
  const { pathname, search } = useLocation();
  const breadcrumbLinks = useBreadcrumbLinks({ current: category.name });
  const heroImages = getHeroImages(category);
  const siblings = category.parent?.children;
  const mergedCategories = siblings && siblings.length > 0 ? siblings : category.children;
  const subCategories = [];
  const [value, setValue] = useState(null);

  if (mergedCategories.length > 0) {
    subCategories.push({ name: t("CATEGORY.VIEW_ALL"), url: category.parent?.url ?? category.url }, ...mergedCategories);
  }

  return (
    <div className={styles.block}>
      <Helmet
        title={meta.title}
        meta={meta.data}
      />

      <ProductListHero
        title={category.heroHeading ?? ""}
        subTitle={category.heroSubHeading ?? ""}
        description={category.heroDescription ?? ""}
        totalCount={category.products.totalCount}
        images={heroImages}
        breadcrumbLinks={breadcrumbLinks}
        breadcrumbCurrent={category.name}
        short={category.heroModifier === "short"}
      />

      {subCategories.length > 0 &&
        <ul className={styles.subCategories}>
          {subCategories.map(({ name, url }) => (
            <li key={name}>
              <Button
                to={url}
                variant="gray"
              >
                {name}
              </Button>
            </li>
          ))}
        </ul>
      }

      <div className={styles.dropdown}>
        <Dropdown value={value} placeholder={t("CATEGORY.SUBCATEGORIES")} onChange={setValue}>
          {subCategories.length > 0 && subCategories.map(category => (
            <DropdownItem key={category.name} value={category.name}>
              <Link to={category.url}>
                {category.name}
              </Link>
            </DropdownItem>
          ))}
        </Dropdown>
      </div>

      <FilterableProductList
        updating={updating}
        productList={category.products}
        load={load}
        breadcrumbLinks={[...breadcrumbLinks, {
          pathname,
          search,
          title: category.name,
          hint: {
            name: category.name,
          },
        }]}
        category={category.name}
      />
    </div>
  );
};

export const HintCategoryView = ({ category }: HintCategoryProps): React$Node => {
  const breadcrumbLinks = useBreadcrumbLinks({ current: category.name });

  return (
    <div className={styles.block}>
      <Helmet title={category.name} />
      <ProductListHeroHint
        breadcrumbLinks={breadcrumbLinks}
        breadcrumbCurrent={category.name}
      />
      <FilterableProductListHint />
    </div>
  );
};

export default CategoryView;
