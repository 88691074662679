/* @flow */

import React, { useRef, useEffect, useState } from "react";
import cn from "classnames";
import { useHistory } from "react-router";
import { Link } from "react-router-dom";
import { useTranslate } from "@awardit/react-use-translate";
import useFormat from "helpers/use-format";
import Wrapper from "components/Wrapper";
import useSearchSuggestions from "helpers/use-search-suggestions";
import { useAnalytics } from "context/analytics";
import ChevronIcon from "icons/chevron-big.svg";
import SearchIcon from "icons/search.svg";
import CloseIcon from "icons/close.svg";

import styles from "./styles.scss";

type Props = {
  className?: string,
  onBack: () => void,
  onClose?: () => void,
};

const SearchMenu = ({ className, onClose, onBack }: Props): React$Node => {
  const t = useTranslate();
  const history = useHistory();
  const { formatPrice } = useFormat();
  const inputRef = useRef(null);
  const [searchQuery, setSearchQuery] = useState("");
  const searchSuggestions = useSearchSuggestions(searchQuery);
  const analytics = useAnalytics();

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  }, []);

  const onSubmit = (e: Event) => {
    e.preventDefault();

    if (inputRef.current !== null) {
      const { value } = inputRef.current;

      if (!value || value.length <= 2) {
        return;
      }

      history.push(`/search/${value}`);
      analytics.searchTerm(value);
    }
  };

  return (
    <form className={cn(className, styles.block)} onSubmit={onSubmit}>
      <div className={styles.back} onClick={onBack}>
        <ChevronIcon />
      </div>

      <Wrapper className={styles.container}>
        <button type="submit" className={styles.submit}>
          <SearchIcon className={cn(styles.icon, styles.searchIcon)} />
        </button>

        <div className={styles.inputWrapper}>
          <input
            ref={inputRef}
            className={styles.input}
            type="text"
            placeholder={t("SEARCH.PLACEHOLDER")}
            value={searchQuery}
            onChange={e => setSearchQuery(e.target.value)} />
        </div>

        {onClose &&
        <button type="button" className={styles.close} onClick={onClose}>
          <CloseIcon className={cn(styles.icon, styles.closeIcon)} />
        </button>}
      </Wrapper>

      {searchQuery.length > 2 &&
      <section className={styles.suggestionsSection}>
        <div className={styles.searchQuery}>
          <Link
            to={`/search/${searchQuery}`}
            className={styles.searchQueryLink}
          >
            {`"${searchQuery}"`}
          </Link>
        </div>

        <div className={styles.suggestions}>
          {searchSuggestions.map(x => (
            <div key={x.name} className={styles.suggestion}>
              <Link className={styles.suggestionLink} to={x.urlKey}>
                <div className={styles.suggestionLeft}>
                  {Boolean(x.image) && (
                    <img
                      alt={x.name}
                      src={x.image}
                      className={styles.image}
                    />
                  )}
                  <div className={styles.suggestionBody}>
                    <span className={styles.name}>{x.name}</span>
                    {typeof x.price === "number" && (
                      <span className={styles.price}>{formatPrice(x.price)}</span>
                    )}
                  </div>
                </div>
                <div className={styles.suggestionRight}>
                  <div className={styles.suggestionBody}>
                    <span>{t(`SEARCH.TYPE.${x.type.toUpperCase()}`)}</span>
                  </div>
                </div>
              </Link>
            </div>
          ))}
        </div>
      </section>}
    </form>
  );
};

SearchMenu.defaultProps = {
  className: "",
  onClose: null,
};

export default SearchMenu;
