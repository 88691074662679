/* @flow */

import React from "react";
import { Helmet } from "react-helmet-async";
import Wrapper from "components/Wrapper";
import { useTranslate } from "@awardit/react-use-translate";

const NotFound = (): React$Node => {
  const t = useTranslate();

  return (
    <Wrapper>
      <Helmet
        title={t("404.TITLE")}
      />

      <h1>{t("404.HEADING")}</h1>
      <h2>{t("404.SUBHEADING")}</h2>
    </Wrapper>
  );
};

export default NotFound;
