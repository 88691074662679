/* @flow */

import type { BreadcrumbLink } from "@crossroads/ui-components";

import React from "react";
import cn from "classnames";
import { Breadcrumbs } from "@crossroads/ui-components";

import styles from "./styles.scss";
type Props = {
  className?: string,
  root?: {
    url: string,
    title?: string,
    icon?: React$ComponentType<any>,
  },
  current: string,
  links?: $ReadOnlyArray<BreadcrumbLink>,
};

const Delimeter = () => <span className={styles.delimeter}>-</span>;

const CustomBreadcrumbs = ({ className, ...rest }: Props): React$Node => {
  return (
    <Breadcrumbs
      {...rest}
      className={cn(styles.block, className)}
      Delimeter={Delimeter}
    />
  );
};

export default CustomBreadcrumbs;
