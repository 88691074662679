/* @flow */

import type { SortableProductList } from "shop-state/types";

import React from "react";
import { Helmet } from "react-helmet-async";
import { useData, useSendMessage } from "crustate/react";
import { useTranslate } from "@awardit/react-use-translate";
import FilterableProductList, { FilterableProductListHint } from "components/FilterableProductList";
import ProductListHero, { ProductListHeroHint } from "components/ProductListHero";
import { allProducts } from "@crossroads/shop-state/all-products";
import { AllProductsData } from "data";
import { useLocation } from "react-router";
import useBreadcrumbLinks from "helpers/use-breadcrumb-links";

import styles from "./styles.scss";

type Props = {
  productList: SortableProductList,
  updating: boolean,
};

const AllProductsViewWrapper = (): React$Node => {
  const allProducts = useData(AllProductsData);

  if (allProducts.state === "LOADED" || allProducts.state === "UPDATING") {
    return (
      <AllProductsView
        productList={allProducts.data}
        updating={allProducts.state === "UPDATING"}
      />
    );
  }

  return <HintAllProductsView />;
};

const AllProductsView = ({ productList, updating }: Props): React$Node => {
  const t = useTranslate();
  const sendMessage = useSendMessage();
  const loadFn = location => sendMessage(allProducts(location));
  const location = useLocation();
  const breadcrumbLinks = useBreadcrumbLinks();

  return (
    <div className={styles.block}>
      <Helmet title={t("ALL_PRODUCTS.TITLE")} />
      <ProductListHero
        title={t("ALL_PRODUCTS.TITLE")}
        totalCount={productList.totalCount}
        breadcrumbLinks={breadcrumbLinks}
        breadcrumbCurrent={t("ALL_PRODUCTS.TITLE")}
      />

      <FilterableProductList
        updating={updating}
        productList={productList}
        load={loadFn}
        breadcrumbLinks={[{
          pathname: "/all-products",
          title: t("ALL_PRODUCTS.TITLE"),
          search: location.search,
          hint: {
            name: t("ALL_PRODUCTS.TITLE"),
          },
        }]}
        category={t("ALL_PRODUCTS.TITLE")}
      />
    </div>
  );
};

const HintAllProductsView = () => {
  const t = useTranslate();
  const breadcrumbLinks = useBreadcrumbLinks();

  return (
    <div>
      <Helmet title={t("ALL_PRODUCTS.TITLE")} />
      <ProductListHeroHint
        title={t("ALL_PRODUCTS.TITLE")}
        breadcrumbLinks={breadcrumbLinks}
        breadcrumbCurrent={t("ALL_PRODUCTS.TITLE")}
      />
      <FilterableProductListHint />
    </div>
  );
};

export default AllProductsViewWrapper;

