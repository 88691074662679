/* @flow */

import type { Match } from "react-router";

import React, { useContext, useEffect } from "react";
import { Link, NavLink, useLocation } from "react-router-dom";
import cn from "classnames";
import { useTranslate } from "@awardit/react-use-translate";
import { StoreInfoContext } from "entrypoint/shared";
import { useData } from "crustate/react";
import { QuoteData } from "data";
import { CartMini } from "@crossroads/ui-components";
import Wrapper from "components/Wrapper";
import CartCounter from "components/AppHeader/CartCounter";
import SearchMenu from "../SearchMenu";
import Logo from "components/AppHeader/Logo";
import HamburgerMenu from "components/AppHeader/HamburgerMenu";
import Button from "components/Button";
import { MODE } from "state/view-mode";
import { useUi } from "helpers/ui";
import SearchIcon from "icons/search.svg";
import HamburgerIcon from "icons/hamburger.svg";

import styles from "./styles.scss";

const NavLinks = (): React$Node => {
  const {
    viewMode,
  } = useUi();
  const { categories } = useContext(StoreInfoContext);

  const navLinkIsActive = (match: Match) => {
    if (!match) {
      return false;
    }

    if (viewMode !== MODE.NORMAL) {
      return false;
    }

    return true;
  };

  return (
    categories.map(category => (
      <NavLink
        key={category.name}
        to={category.url}
        activeClassName={styles.active}
        className={cn(
          styles.item,
          styles.item__allproducts
        )}
        isActive={navLinkIsActive}
      >
        <span>{category.name}</span>
      </NavLink>
    ))
  );
};

const MobileSubNavigations = ({ onClose }: {
  onClose: () => void,
}) => {
  const t = useTranslate();

  const {
    setSubNavOpen,
    viewMode,
    setViewMode,
  } = useUi();

  const closeMenu = () => setViewMode(MODE.NORMAL);

  return (
    viewMode === MODE.SEARCH &&
      <nav className={cn(styles.mobile, styles.subNav, styles.search)}>
        <SearchMenu heading={t("HEADER.SEARCH")} onClose={onClose} onItemClick={closeMenu} onBack={() => setSubNavOpen(false)} />
      </nav>
  );
};

const Nav = ({
  onCheckout,
}: { onCheckout: boolean }): React$Node => {
  const t = useTranslate();
  const location = useLocation();
  const quoteData = useData(QuoteData);
  const showHeader = true;
  const {
    setHamburgerOpen,
    toggleHamburger,
    setSubNavOpen,
    viewMode,
    setViewMode,
    closeHamburger,
  } = useUi();

  const toggleSearch = () =>
    setViewMode(viewMode === MODE.SEARCH ? MODE.NORMAL : MODE.SEARCH);

  // Close menus when switching page
  useEffect(() => {
    setViewMode(MODE.NORMAL);
    setSubNavOpen(false);
    setHamburgerOpen(false);
  }, [setViewMode, setSubNavOpen, setHamburgerOpen, location.pathname]);

  return (
    <div className={cn(styles[`mode__${viewMode}`])}>
      {!onCheckout &&
        <div className={styles.topBar}>
          <Wrapper className={styles.wrapper}>
            <Link
              className={styles.link}
              to={t("CUSTOMER_SERVICE.LINK")}
              title={t("CUSTOMER_SERVICE.TEXT")}
            >
              {t("CUSTOMER_SERVICE.TEXT")}
            </Link>
            <a
              className={styles.link}
              target="_blank"
              rel="noopener noreferrer"
              href={t("HEADER.SECTOR.URL")}
              title={t("HEADER.SECTOR.TITLE")}
            >
              {t("HEADER.SECTOR.TITLE")}
            </a>
          </Wrapper>
        </div>
      }
      <div className={styles.block}>
        {viewMode === MODE.SEARCH &&
          <div className={cn(styles.desktop, styles.subNav, styles.search)}>
            <SearchMenu onClose={() => toggleSearch()} onBack={() => setSubNavOpen(false)} />
          </div>
        }
        <div className={styles.left}>
          <Link className={cn(styles.item, styles.item__logo)} to="/">
            <Logo
              className={styles.logo}
              onClick={() => setViewMode(MODE.NORMAL)}
            />
          </Link>

          {viewMode !== MODE.SEARCH && !onCheckout ? (
            <NavLinks />
          ) : null}
        </div>
        <div className={styles.right}>
          <nav className={styles.nav}>
            {!onCheckout && viewMode !== MODE.SEARCH &&
              <Button
                className={cn(
                  styles.item,
                  styles.item__search,
                  { [styles.active]: viewMode === MODE.SEARCH }
                )}
                onClick={toggleSearch}
              >
                <SearchIcon />
              </Button>
            }

            <CartCounter
              className={cn(
                styles.item, styles.item__cartCounter, { [styles.noMargin]: onCheckout }
              )}
              isOpen={viewMode === MODE.CART}
              openMiniCart={() =>
                setViewMode(viewMode === MODE.CART ? MODE.NORMAL : MODE.CART)
              } />
          </nav>
        </div>

        {!onCheckout &&
          <Button
            className={cn(styles.item, styles.hamburger, styles.padding)}
            onClick={toggleHamburger}
          >
            <HamburgerIcon />
          </Button>
        }

        {viewMode === MODE.CART &&
          <CartMini
            quoteData={quoteData}
            showHeader={showHeader}
            ctaUrl={t("URL.CHECKOUT_CART")}
            className={cn(styles.desktop, styles.miniCart)}
            onClose={() => setViewMode(MODE.NORMAL)}
          />
        }

        {!onCheckout &&
          <HamburgerMenu>
            <MobileSubNavigations
              className={styles.mobile}
              onClose={() => closeHamburger(styles.animationDuration)}
            />
          </HamburgerMenu>
        }
      </div>

      {viewMode === MODE.CART &&
        <CartMini
          quoteData={quoteData}
          showHeader={showHeader}
          ctaUrl={t("URL.CHECKOUT_CART")}
          className={cn(styles.mobile, styles.miniCart)}
          onClose={() => setViewMode(MODE.NORMAL)}
        />
      }

      {viewMode !== MODE.NORMAL &&
        <div className={styles.dim} onClick={() => setViewMode(MODE.NORMAL)} />
      }
    </div>
  );
};

export default Nav;
