module.exports = {
  host: "localhost",
  port: 9148,
  apiHost: {
    server: "http://127.0.0.1",
    client: "",
    headers: ["SSL", "Ssl-Offload", "X-Forward-Proto", "Host", "X-Real-Ip", "X-Forwarded-For", "Cookie"],
  },
  googleAnalytics: [
    { store: "se", currencyCode: "SEK", accounts: ["UA-5572085-45", "G-FYRZEWZGR1"] },
    { store: "no", currencyCode: "NOK", accounts: ["UA-5572085-97", "G-EN8E73QE7Z"] },
    { store: "fi", currencyCode: "EUR", accounts: ["UA-5572085-106", "G-CRL3NG9P9S"] },
  ],
  isProduction: true,
  proxyHost: null,
  altapay: {
    src: "https://awardit.altapaysecure.com/checkout/v1/js/AltaPaySDK.js",
  },
  cspUri: null,
};
