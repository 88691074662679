/* @flow */

import React from "react";
import cn from "classnames";
import { Carousel, Arrows } from "@crossroads/ui-components";
import useBrowserDimensions from "helpers/use-browser-dimensions";

import styles from "./styles.scss";

type Props = {
  className?: string,
  items: Array<React$Node>,
  loopAround?: boolean,
  autoplay?: boolean,
  limitScrollIndex?: boolean,
  dragThreshold?: number,
  timer?: number,
  slidesToShow?: number,
  children?: React$Node,
};

const small = Number.parseInt(styles.small, 10);

const CustomCarousel = ({
  className, items, children, slidesToShow = 4, ...rest
}: Props): React$Node => {
  const { width: browserWidth } = useBrowserDimensions();
  const desktop = browserWidth >= small;

  if (browserWidth === 0) {
    return (
      <div className={cn(className, styles.containerServer)}>
        {items}
      </div>
    );
  }

  if (items.length === 1 || (desktop && items.length > 1 && items.length <= slidesToShow)) {
    return (
      <div className={cn(className, styles.container)}>
        {items}
      </div>
    );
  }

  return (
    <Carousel
      {...rest}
      className={className}
      items={items}
      slidesToScroll={slidesToShow}
      slidesToShow={slidesToShow}
    >
      <Arrows className={styles.carouselControls} />
    </Carousel>
  );
};

export default CustomCarousel;
