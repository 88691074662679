/* @flow */

import type { StateData } from "crustate/react";

import { createStateData } from "crustate/react";

import { BrandModel } from "@crossroads/shop-state/brand";
import { HomeModel } from "state/home";
import { MessagesModel } from "@crossroads/shop-state/messages";
import { AllProductsModel } from "@crossroads/shop-state/all-products";
import { OrderModel } from "state/order";
import { PopularModel } from "state/popular";
import { CmsModel } from "state/cms";
import { QuoteModel } from "state/quote";
import { RouteModel } from "@crossroads/shop-state/route";
import { SearchModel } from "@crossroads/shop-state/search";
import { ViewModeModel } from "state/view-mode";

export const AllProductsData: StateData<typeof AllProductsModel> =
  createStateData<typeof AllProductsModel>(AllProductsModel);
export const BrandData: StateData<typeof BrandModel> =
  createStateData<typeof BrandModel>(BrandModel);
export const HomeData: StateData<typeof HomeModel> =
  createStateData<typeof HomeModel>(HomeModel);
export const MessagesData: StateData<typeof MessagesModel> =
  createStateData<typeof MessagesModel>(MessagesModel);
export const OrderData: StateData<typeof OrderModel> =
  createStateData<typeof OrderModel>(OrderModel);
export const PopularData: StateData<typeof PopularModel> =
  createStateData<typeof PopularModel>(PopularModel);
export const CmsData: StateData<typeof CmsModel> =
  createStateData<typeof CmsModel>(CmsModel);
export const QuoteData: StateData<typeof QuoteModel> =
  createStateData<typeof QuoteModel>(QuoteModel);
export const RouteData: StateData<typeof RouteModel> =
  createStateData<typeof RouteModel>(RouteModel);
export const SearchData: StateData<typeof SearchModel> =
  createStateData<typeof SearchModel>(SearchModel);
export const ViewModeData: StateData<typeof ViewModeModel> =
  createStateData<typeof ViewModeModel>(ViewModeModel);
