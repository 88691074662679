/* @flow */

import type { ProductCardProduct } from "shop-state/types";
import type { Model } from "crustate";
import type { Response } from "./util";
import { updateData } from "crustate";

type AbstractBlock = {
  heading: string,
  text: string,
  cta: string,
  link: string,
  modifier: string,
  ribbon: string,
};

export type CarouselItem = {
  image?: { x1: string, x2: string },
  name: string,
  url: string,
};

export type BlockCategory = AbstractBlock & {
  smallImage?: { x1: string, x2: string },
  mediumImage?: { x1: string, x2: string },
  largeImage: {
    w1280: string,
    w1440: string,
    w1600: string,
    w1920: string,
    w2560: string,
    w3840: string,
    w4320: string,
    },
};

export type BlockSplit = AbstractBlock & {
  image: { x1: string, x2: string },
  imageSmall: { x1: string, x2: string },
};

export type Home = {
  popularProducts: {
    category: {
      name: string,
      url: string,
      products: {
        items: Array<ProductCardProduct>,
      },
    },
  },
  categoryPreview1: {
    category: {
      name: string,
      url: string,
      products: {
        items: Array<ProductCardProduct>,
      },
    },
  },
  categoryPreview2: {
    category: {
      name: string,
      url: string,
      products: {
        items: Array<ProductCardProduct>,
      },
    },
  },
  hero: {
    block: BlockCategory,
  },
  productPreview: {
    product: {
      name: string,
      url: string,
      attributes: {
        shortDescription: string,
        manufacturer: string,
        image: {
          src: string,
        },
      },
    },
  },
};

type Data =
  | { state: "LOADING" }
  | { state: "LOADED", data: Home }
  | { state: "ERROR", error: string };

export type HomeResponse = Response<typeof HOME_RESPONSE, ?Home>;

export type HomeRequest = {
  tag: typeof HOME_REQUEST,
  type: "home",
};

export const HOME_RESPONSE: "response/home" = "response/home";
export const HOME_REQUEST: "request/home" = "request/home";

export const HomeModel: Model<Data, { }, HomeRequest | HomeResponse> = {
  id: "home",
  init: () =>
    updateData(
      { state: "LOADING", type: "home" },
      { tag: HOME_REQUEST, type: "home" }
    ),
  update: (state, msg) => {
    switch (msg.tag) {
      case HOME_RESPONSE:
        if (msg.error) {
          return updateData({ state: "ERROR", error: msg.error });
        }

        if (msg.data) {
          return updateData({ state: "LOADED", data: msg.data });
        }

        break;
      default:
    }
  },
};
