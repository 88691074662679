/* @flow */

import React from "react";
import { useData } from "crustate/react";
import { useAnalytics } from "context/analytics";
import { quote as quoteQuery } from "queries";
import { useClient } from "entrypoint/shared";

import { QuoteData } from "data";
import { setQuoteDiscountCode, removeQuoteDiscountCode } from "state/quote";
import { CheckoutCart } from "@crossroads/shop-views";
import useFormat from "helpers/use-format";

const Cart = (): React$Node => {
  const quote = useData(QuoteData);
  const analytics = useAnalytics();
  const { formatPrice } = useFormat();

  if (!quote.data) {
    return null;
  }

  return (
    <CheckoutCart
      hasBrandView={false}
      analytics={{ context: analytics, hasAlternativeContext: true }}
      QuoteData={QuoteData}
      formatPrice={formatPrice}
      setQuoteDiscountCode={setQuoteDiscountCode}
      removeQuoteDiscountCode={removeQuoteDiscountCode}
      quoteQuery={quoteQuery}
      useClient={useClient}
    />
  );
};

export default Cart;
