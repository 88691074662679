/* @flow */

import React from "react";
import { useTranslate } from "@awardit/react-use-translate";
import Wrapper from "components/Wrapper";
import Button from "components/Button";

import styles from "./styles.scss";

type Props = {
  product: {
    name: string,
    url: string,
    attributes: {
      shortDescription: string,
      image: { src: string },
      manufacturer: string,
    },
  },
};

const ProductPreview = ({ product }: Props): React$Node => {
  const t = useTranslate();
  const { shortDescription, image, manufacturer } = product.attributes;

  return (
    <Wrapper className={styles.block}>
      <div className={styles.row}>
        <div className={styles.column}>
          <img alt={product.name} className={styles.image} src={image.src} />
        </div>
        <div className={styles.column}>
          <h1 className={styles.title}>{product.name}</h1>
          <h2 className={styles.manufacturer}>{manufacturer}</h2>
          <div
            dangerouslySetInnerHTML={{ __html: shortDescription }}
            className={styles.description}
          />
          <Button variant="primary" to={product.url}>{t("HOMEVIEW.ORDER_NOW")}</Button>
        </div>
      </div>
    </Wrapper>
  );
};

export default ProductPreview;
