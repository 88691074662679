/* @flow */

import type { Node as ReactNode } from "react";
import type { Client, GraphQLResponse, QueryRunner } from "@awardit/graphql-ast-client";
import type { StoreInfo } from "shop-state/types";

import React, { useContext, createContext } from "react";

export type ClientProviderProps = {
  children: ReactNode,
  value: Client<{}>,
};

export const StoreInfoContext: React$Context<StoreInfo> = createContext<StoreInfo>({
  info: {
    name: "",
    locale: "sv_SE",
    defaultDescription: "",
    titlePrefix: "",
    titleSuffix: "",
    defaultTitle: "",
    baseCurrencyCode: "",
    defaultCountry: {
      code: "SE",
    },
    countries: [],
    baseUrl: "",
    popUp: null,
  },
  categories: [],
  brands: [],
  date: "",
});

export const createRunQuery = (
  fetch: typeof fetch,
  prefix: string,
  postfix?: string
): QueryRunner => request => fetch(`${prefix}/graphql${postfix || ""}`, {
  method: "POST",
  headers: {
    "Content-Type": "application/json",
  },
  body: JSON.stringify(request),
}).then(handleFetchResponse);

export const handleFetchResponse = (
  response: Response
): Promise<GraphQLResponse<mixed>> => response.text()
  .then((bodyText: string): GraphQLResponse<mixed> => {
    try {
      // Attempt to parse all, ignore response.ok
      return JSON.parse(bodyText);
    }
    catch (e) {
      const error = new Error(`JSON Parse error: ${e && typeof e.getMessage === "function" ? e.getMessage() : e}: ${bodyText}`);

      (error: any).bodyText = bodyText;
      (error: any).response = response;
      (error: any).statusCode = response.status;

      throw error;
    }
  });

const ClientContext = createContext<Client<{}> | null>(null);

export const ClientProvider = ({ children, value }: ClientProviderProps): React$Node =>
  <ClientContext.Provider value={value}>{children}</ClientContext.Provider>;

export const useClient = (): Client<{}> => {
  const client = useContext(ClientContext);

  if (!client) {
    throw new Error("useClient(): Usage must be wrapped in a <ClientProvider />.");
  }

  // $FlowIssue[escaped-generic]
  return client;
};

export const APP_KEY = "root";

