/* @flow */

import React, { useEffect } from "react";
import { useLocation } from "react-router";
import usePrevious from "helpers/use-previous";
import Nav from "./Nav";
import { QuoteData } from "data";
import { setQuoteDiscountCode } from "state/quote";
import { useData, useSendMessage } from "crustate/react";
import Cookies from "js-cookie";
import { MODE } from "state/view-mode";
import { useUi } from "helpers/ui";
import { useTranslate } from "@awardit/react-use-translate";
import SystemMessages from "components/SystemMessages";

import styles from "./styles.scss";

const AppHeader = (): React$Node => {
  const t = useTranslate();
  const location = useLocation();
  const { setHamburgerOpen, setSubNavOpen, setViewMode } = useUi();
  const sendMessage = useSendMessage();
  const quoteData = useData(QuoteData);
  const prevQuoteData = usePrevious(quoteData);
  const onCheckout = (location.pathname).includes("checkout") &&
    !(location.pathname).includes("success");

  // Open mini cart when adding to cart
  useEffect(() => {
    if (location.pathname === t("URL.CHECKOUT_CART") ||
        !prevQuoteData ||
        (quoteData.state !== "LOADED" || prevQuoteData.state !== "ADDING_ITEM")) {
      return;
    }

    const count = quoteData.data.items.reduce((a, { qty }) => a + qty, 0);
    const prevCount = prevQuoteData.data.items.reduce((a, { qty }) => a + qty, 0);

    if (count > prevCount) {
      setViewMode(MODE.CART);
      setSubNavOpen(false);
      setHamburgerOpen(false);
    }
  }, [setViewMode, prevQuoteData, quoteData, location, setSubNavOpen, setHamburgerOpen]);

  useEffect(() => {
    const coupon = Cookies.get("coupon");
    if (quoteData.state === "LOADED" && quoteData.data && quoteData.data.items.length > 0 && !(quoteData.data.coupon) && coupon !== undefined) {
      sendMessage(setQuoteDiscountCode(coupon));
    }
  }, [quoteData, sendMessage]);

  return (
    <div className={styles.block}>
      <SystemMessages />
      <Nav onCheckout={onCheckout} />
    </div>
  );
};

export default AppHeader;
