/* @flow */

import React, { useContext } from "react";
import { Link } from "react-router-dom";
import Wrapper from "components/Wrapper";
import cn from "classnames";
import { useTranslate } from "@awardit/react-use-translate";
import Logo from "components/AppHeader/Logo";
import Button from "components/Button";
import { StoreInfoContext } from "entrypoint/shared";

import Facebook from "icons/facebook.svg";
import Instagram from "icons/instagram.svg";
import LinkedIn from "icons/linkedIn.svg";

import styles from "./styles.scss";

type Props = {
  className: string,
  setToggleCookieDialogue: (value: boolean) => void,
};

const AppFooter = ({ className = "", setToggleCookieDialogue }: Props): React$Node => {
  const t = useTranslate();
  const { categories } = useContext(StoreInfoContext);

  const needHelp = () => (
    <section className={styles.needHelp}>
      <p className={styles.title}>{t("CUSTOMER_SERVICE.TEXT")}</p>

      <nav className={styles.nav}>
        <p>{t("FOOTER.NEED_HELP.TITLE")}</p>
        <Link className={styles.link} to={t("CUSTOMER_SERVICE.LINK")}>
          <Button className={styles.button} type="button" variant="primary" size="small">
            {t("FOOTER.NEED_HELP.CONTACT")}
          </Button>
        </Link>
        <div className={styles.helpLinks}>
          <Link
            to="/privacy-policy"
            className={styles.link}
          >
            <span>{t("FOOTER.NEED_HELP.PRIVACY")}</span>
          </Link>
          <Link
            to="/privacy-policy"
            className={styles.link}
          >
            <span>{t("FOOTER.NEED_HELP.COOKIE_POLICY")}</span>
          </Link>
          <Button
            className={styles.link}
            onClick={() => setToggleCookieDialogue(true)}
          >
            {t("FOOTER.NEED_HELP.MANAGE_COOKIES")}
          </Button>
        </div>
      </nav>
    </section>
  );

  const storeCategories = () => (
    <section className={styles.categories}>
      <p className={styles.title}>{t("FOOTER.LINKS.CATEGORIES")}</p>
      <nav className={styles.nav}>
        {categories.map(category => (
          <Link
            key={category.name}
            to={category.url}
            className={styles.link}
          >
            <span>{category.name}</span>
          </Link>
        ))}
      </nav>
    </section>
  );

  const logo = () => (
    <section className={styles.copyright}>
      <Logo className={styles.logo} />

      <nav className={styles.socialmediaLinks}>
        <a
          className={styles.link}
          rel="noopener noreferrer"
          target="_blank"
          href="https://www.facebook.com/SectorAlarmSverige"
        >
          <span className={cn(styles.social, styles.facebook)} />
          <Facebook />
        </a>
        <a
          className={styles.link}
          rel="noopener noreferrer"
          target="_blank"
          href="https://www.instagram.com/sectoralarmsverige"
        >
          <span className={cn(styles.social, styles.linkedin)} />
          <LinkedIn />
        </a>
        <a
          className={styles.link}
          rel="noopener noreferrer"
          target="_blank"
          href="https://www.linkedin.com/company/sector-alarm-sverige"
        >
          <span className={cn(styles.social, styles.instagram)} />
          <Instagram />
        </a>
      </nav>
    </section>
  );

  return (
    <div className={cn(styles.block, className)}>
      <div className={styles.large}>
        <Wrapper className={styles.wrapper}>
          <div className={styles.col}>
            {needHelp()}
          </div>
          <div className={styles.col}>
            {storeCategories()}
          </div>
          <div className={styles.col}>
            {logo()}
          </div>
        </Wrapper>
      </div>

      <div className={styles.small}>
        <Wrapper className={styles.wrapper}>
          <div className={styles.col}>
            {needHelp()}
            {storeCategories()}
          </div>
        </Wrapper>

        <div className={styles.col}>
          {logo()}
        </div>
      </div>
      <div className={styles.bottomBar}>
        <p dangerouslySetInnerHTML={{ __html: t("FOOTER.COPYRIGHT", { year: new Date().getFullYear() }) }} />
      </div>
    </div>
  );
};

export default AppFooter;
